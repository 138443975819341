// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/App.js

import React, { useState } from 'react';
import { Provider } from 'react-redux';

// นำเข้าไฟล์ CSS
import './styles/App.css';
// นำเข้า components และ pages
import { AuthProvider } from './components/AuthContext';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/services';
import About from './pages/about';
import Contact from './pages/contact';
import Gallery from './pages/gallery';
import Register from './pages/register';
import Banner from './components/banner';
import Login from './pages/login';
import Forgot from './pages/forgot';

import Profile from './pages/profile';
import Order from './pages/order';

import Footer from './pages/footer';
import translations from './data/translations.json';
import Navbar from './components/Navbar';
import Booking from './pages/booking';
import BookingScheduleStep from './components/BookingScheduleStep';
import BookingPaymentFormStep from './components/BookingPaymentFormStep';
import SomeOtherInvoice from './components/SomeOtherInvoice';
import store from './store/StoreBookingPaymentFormStep';

import MentionsLegales from './components/MentionsLegales';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiesPolicy';


const App = () => {
  const [language, setLanguage] = useState('EN'); // Manage language state at the top level

  // Unified function to toggle language
  const toggleLanguage = () => {
    setLanguage((currentLanguage) => (currentLanguage === 'EN' ? 'FR' : 'EN'));
  };

  return (

    //รับ store Redux เป็น prop และทำให้มันสามารถใช้งานได้กับคอมโพเนนต์ย่อยใดๆ ที่ต้องการเข้าถึง store Redux
    <Provider store={store}>
      <div className="App">
        <AuthProvider>

          <Navbar language={language} changeLanguage={toggleLanguage} translations={translations} />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/booking/step1" element={<BookingScheduleStep />} />
            <Route path="/payment/step2" element={<BookingPaymentFormStep />} />
            <Route path="/payment/invoice" element={<SomeOtherInvoice language={language} />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/order" element={<Order />} />

            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies-policy" element={<CookiePolicy />} />




          </Routes>
          <Footer />
        </AuthProvider>
      </div>
    </Provider>
  );
}

export default App;
